import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Link from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SvgProductDesign from '../SVGs/ProductDesign';
import SvgProductEngineering from '../SVGs/ProductEngineering';
import SvgSupport from '../SVGs/Support';
import SvgServices from '../SVGs/Services';
import SvgArrow from '../SVGs/Arrow';
import SvgHomeBanner from '../SVGs/HomeBanner';
import ProjectsSection from '../components/projectSection';

const windowGlobal = typeof window !== 'undefined' && window;

const testimonials = [
  { _id: '001', accessKey: 1, author: 'Michael R Roberts CEO', location: 'Market House, USA', feedback: '“After reviewing several designs by different people I wound up choosing 7th Pillar. Their work was the best! Friendly, quick and open to feedback. I would use this provider again!”' },
  { _id: '002', accessKey: 2, author: 'Rocco Cerullo', location: 'Artisian Designs, US', feedback: '“Rashin and his team at 7th Pillar have proven to be very efficient, professional and go above and beyond. They make all efforts to complete work to my satisfaction. I highly recommend them.”' },
  { _id: '003', accessKey: 3, author: 'Eric Huang', location: 'GetFlexion.com, USA', feedback: '“7th Pillar team was extremely courteous, communicated well, and was not afraid to speak their mind on their expertise or things they believed were in the positive direction of the project. We appreciated this.”' },
  { _id: '004', accessKey: 4, author: 'Antony', location: 'My Wire, USA', feedback: '“Their design work was the best, and they took direction perfectly and implemented our desired changes promptly and accurately. We would work with 7th Pillar again without hesitation.”' },
  { _id: '005', accessKey: 5, author: 'Jonathan', location: 'Meengo, Australia', feedback: '“Amazing job and highly recommended. 7th Pillar team keeps amazing us with their creativity, deliverable, understanding of the project and they work fast ! Highly recommended. ++++++”' },
  { _id: '006', accessKey: 6, author: 'Mike Robinson', location: 'Adventure Hotels of Costa Rica', feedback: '“For our project we received 90 entries from about 30 different designers and this was the winner so I am very satisfied with it. Rashin (and the entire 7th Pillar crew) are great to work with.”' },
];

const services = [
  {
    _id: 'service_1',
    pathId: 'draw-on-scroll-services-1',
    img: <SvgProductDesign pathId={`draw-on-scroll-services-1`} style={{ height: '15rem', width: '15rem', fill: '#fff' }} />,
    title: 'Product Design',
    description: 'Our product design team can help in conceptualizing your digital product idea. We do market research, gather feedback, conceptualize user interface design and design the architecture of the entire product.',
  },
  {
    _id: 'service_2',
    pathId: 'draw-on-scroll-services-2',
    img: <SvgProductEngineering pathId={`draw-on-scroll-services-2`} style={{ height: '15rem', width: '15rem', fill: '#fff' }} />,
    title: 'Product Engineering',
    description: 'We have a strong frontend and backend engineering team that understands your business and builds the product from that orientation to help you achieve your business goals.',
  },
  {
    _id: 'service_3',
    pathId: 'draw-on-scroll-services-3',
    img: <SvgSupport pathId={`draw-on-scroll-services-3`} style={{ height: '15rem', width: '15rem', fill: '#fff' }} />,
    title: 'Support',
    description: 'We provide continuous ongoing support for the growth and maintenance of the product by monitoring user feedback, advising you on product improvements, new trends, developing new features and quickly resolving bugs and issues.',
  },
];

const Banner = () => {
  return (
    <>
      <div className="d-flex align-items-center common-banner" style={{ position: 'relative' }}>
        <Container>
          <Row className="justify-content-start py-3">
            <Col md="8">
              <h3>We build</h3>
              <h1>Digital products to solve business problems</h1>
              <h6 className="font-weight-normal" style={{ maxWidth: 550 }}>
                We are creative digital product agency specialising in
                <span className="text-primary font-weight-bold"> web, mobile and conversational </span>
                apps
              </h6>
            </Col>
          </Row>
        </Container>
        <SvgHomeBanner className="d-none d-lg-block" style={{ position: 'absolute', right: 0, zIndex: -1, bottom: -1, maxWidth: 550 }} />
        <div
          className="d-block d-lg-none"
          style={{
            pointerEvents: 'none',
            height: 350,
            width: 175,
            position: 'absolute',
            right: 0,
            bottom: 0,
            transform: 'translateY(-30%)',
            zIndex: 1000,
            opacity: 0.2,
            overflow: 'hidden',
          }}
        >
          <SvgServices
            style={{
              position: 'absolute',
              right: -175,
              top: 0,
              width: 350,
              height: 350,
            }}
          />
        </div>
      </div>
    </>
  );
};

const Projects = () => {
  return (
    <Container className="pb-5">
      <h2 className="text-white">Our Projects</h2>
      <Row className="justify-content-center py-3">
        <ProjectsSection />
      </Row>
    </Container>
  );
};

const Services = ({ activeHead }) => {
  React.useEffect(() => {
    // get viewport height
    const vh = Math.max(document.documentElement.clientHeight, windowGlobal.innerHeight || 0);
    const serviceSvgPaths = [];
    const serviceSvgPathLength = [];
    const svgContainers = [];
    services.forEach((element) => {
      // get element
      const elem = document.getElementById(element.pathId);
      // get element container
      const cntnr = document.getElementById(element._id);
      // get element length
      const pathLength = elem.getTotalLength();
      // applying styles "strokeDasharray, strokeDashoffset" to elements
      elem.style.strokeDasharray = pathLength;
      elem.style.strokeDashoffset = pathLength;
      // saving for drawing
      serviceSvgPathLength.push(pathLength);
      serviceSvgPaths.push(elem);
      svgContainers.push(cntnr);
    });
    windowGlobal.addEventListener('scroll', () => {
      // Drawing svgs
      services.forEach((element, index) => {
        // get svg dimension
        const svgDimension = svgContainers[index].getBoundingClientRect();
        // calculate percentage to draw
        const scrollPercentage = svgDimension.y + vh > 0 ? -(svgDimension.y - vh / 4) / vh : 0;
        // calculate draw length
        const drawLength = scrollPercentage > 0 ? (serviceSvgPathLength[index] * scrollPercentage) / 1.25 : 0;
        // drawing svg on reverse order
        serviceSvgPaths[index].style.strokeDashoffset = serviceSvgPathLength[index] - drawLength > 0 ? serviceSvgPathLength[index] - drawLength : 0;
      });
    });
    return () => windowGlobal.removeEventListener('scroll', () => {});
  }, []);

  return services.map((service, i) => (
    <>
      <div key={service.services_2} id={service._id} className={`pb-5 mb-5 half-container ${activeHead === i ? 'services-opacity-active' : 'services-opacity'}`}>
        <div className="text-white d-flex" style={{ flexDirection: 'row' }}>
          <h1 className="no-line-height font-weight-bold d-none d-lg-block">0{i + 1}&nbsp;&nbsp;</h1>
          <div>
            <h2> {service.title}</h2>
            <p>{service.description}</p>
          </div>
        </div>
        <div className="d-flex w-100 justify-content-end">{service.img}</div>
      </div>
    </>
  ));
};

const OurServices = () => {
  let vh;
  const [activeHead, setActiveHead] = React.useState(1);
  const handleScroll = () => {
    const service1 = document.getElementById(`service_1`).getBoundingClientRect();
    const service2 = document.getElementById(`service_2`).getBoundingClientRect();
    const service3 = document.getElementById(`service_3`).getBoundingClientRect();
    if (service1.top > -vh / 5) {
      setActiveHead(0);
    } else if (service2.top > -vh / 5) {
      setActiveHead(1);
    } else if (service3.top > -vh / 5) {
      setActiveHead(2);
    }
  };

  React.useEffect(() => {
    vh = Math.max(document.documentElement.clientHeight, windowGlobal.innerHeight || 0);
    windowGlobal.addEventListener('scroll', handleScroll);
    return () => windowGlobal.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Row noGutters className="align-items-start justify-content-start" style={{ position: 'relative' }}>
      <Col xs={12} md={6} className="p-5 align-items-center justify-content-end d-flex our-services">
        <div className="px-0 px-md-5 py-3  half-container">
          <h2>What we do?</h2>
          <h6 style={{ maxWidth: 500 }} className="font-weight-normal">
            We are a full stack web, mobile and conversational design and development agency. We do turnkey development of digital products, right from the ideation stage to launch and growth of the project.
          </h6>
          <Link paintDrip hex="#003bff" to="/getaquote" className="link-no-style">
            <button type="button" className="custom-button">
              GET A QUOTE
            </button>
          </Link>
          <div style={{ height: 350, width: 175, position: 'absolute', overflow: 'hidden', right: 0, top: '20%', opacity: 0.2 }}>
            <SvgServices className="d-none d-md-block" style={{ height: 350, width: 350, borderRadius: '50%' }} />
          </div>
        </div>
      </Col>
      <Col xs={12} md={6} className="d-flex">
        <Row noGutters id="services-scroll-container" className="p-5">
          <Services activeHead={activeHead} />
        </Row>
      </Col>
      <div
        className="d-none d-lg-block"
        style={{
          pointerEvents: 'none',
          height: 350,
          width: 350,
          position: 'absolute',
          right: 0,
          top: -250,
          zIndex: 1000,
          opacity: 0.2,
          overflow: 'hidden',
        }}
      >
        <SvgServices
          style={{
            position: 'absolute',
            right: -175,
            height: '100%',
          }}
        />
      </div>
    </Row>
  );
};

const RatingSlider = () => {
  const [showingSlide, setShowingSlide] = React.useState(1);
  const [previousSlide, setPreviousSlide] = React.useState(testimonials.length);
  const [outAnimation, setOutAnimation] = React.useState(false);
  let xDown = null;
  let yDown = null;

  const moveLeft = () => {
    setOutAnimation(true);
    setPreviousSlide(showingSlide);
    setShowingSlide(showingSlide > 1 ? showingSlide - 1 : testimonials.length);
    setTimeout(() => {
      setOutAnimation(false);
    }, 100);
  };

  const moveRight = () => {
    setPreviousSlide(showingSlide);
    setShowingSlide(showingSlide < testimonials.length ? showingSlide + 1 : 1);
  };
  function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
  }

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        moveLeft();
      } else {
        moveRight();
      }
    }
    xDown = null;
    yDown = null;
  };

  return (
    <div className="p-5">
      <div className="slider-wrapper text-dark">
        <div className="arrow sliderArrowL">
          <button type="button" className="custom-button" style={{ padding: 10, minWidth: 40 }} onClick={() => moveLeft()}>
            <SvgArrow style={{ fill: '#fff', height: 30, width: 16 }} />
          </button>
        </div>
        <ul className="list-inline pillar-slider">
          {testimonials.map((testimonial) => (
            <li key={testimonial._id} className={`${showingSlide === testimonial.accessKey ? 'active-slide' : ''} ${showingSlide === testimonial.accessKey && outAnimation ? 'out' : ''} ${previousSlide === testimonial.accessKey ? 'previous-slide' : ''}`} accessKey={testimonial.accessKey}>
              <div className="card pSliderCard" onTouchStart={(e) => handleTouchStart(e)} onTouchMove={(e) => handleTouchMove(e)}>
                <div className="card-body">
                  <div className="pSFontBody">
                    <p className="testimonial-feedback">{testimonial.feedback}</p>
                  </div>
                  <div className="pSFontFooter">
                    <h6 className="testimonial-author">{testimonial.author}</h6>
                    <p className="mb-0">{testimonial.location}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="arrow sliderArrowR">
          <button type="button" className="custom-button" style={{ padding: 10, minWidth: 40 }} onClick={() => moveRight()}>
            <SvgArrow style={{ fill: '#fff', height: 30, width: 16, transform: 'rotate(180deg)' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

const CustomerRatings = () => {
  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <Container>
        <Row noGutters className="align-items-stretch justify-content-start">
          <Col md="6" className="align-items-center justify-content-center d-flex">
            <div className="text-white" style={{ zIndex: 2 }}>
              <h2 className="w-100 w-md-75">Hear it from our customers</h2>
              <h6 className="w-100 w-md-75">We take great pride in working with amazing people on exciting projects. Here is what they have to say about us.</h6>
              <Link paintDrip hex="#003bff" to="/getaquote" className="link-no-style">
                <button type="button" className="custom-button">
                  GET A QUOTE
                </button>
              </Link>
            </div>
          </Col>
          <Col md="6" className="p-5 align-items-start justify-content-center" style={{ overflow: 'hidden' }}>
            <RatingSlider />
          </Col>
        </Row>
      </Container>

      <div
        className="d-none d-lg-block"
        style={{
          pointerEvents: 'none',
          height: 400,
          width: 400,
          position: 'absolute',
          left: -150,
          top: -150,
          opacity: 0.3,
          overflow: 'hidden',
          borderRadius: '100%',
        }}
      >
        <SvgServices
          style={{
            transform: 'rotate(-180deg)',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
};

class HomePage extends React.PureComponent {
  render() {
    return (
      <>
        <Layout pageInfo={{ pageName: 'index' }} home Banner={<Banner />}>
          <SEO title="Websites, Web Apps, Mobile Apps &amp; Conversational Apps" keywords={[`gatsby`, `react`, `bootstrap`]} />
          <Projects />
          <OurServices />
          <CustomerRatings />
        </Layout>
      </>
    );
  }
}

export default HomePage;
