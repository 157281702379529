import React from 'react';

const SvgHomeBanner = (props) => (
  <svg id="Layer_3" {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="Group_90" data-name="Group 90">
      <path
        id="Group_89"
        data-name="Group 89"
        d="M243.2,53.49c107.78,0,195.15,87.38,195.15,195.15S351,443.79,243.2,443.79,48.06,356.42,48.06,248.64,135.43,53.49,243.2,53.49ZM65.35,248.64A177.85,177.85,0,1,0,243.2,70.79,177.85,177.85,0,0,0,65.35,248.64Zm17.3,0A160.56,160.56,0,1,0,243.2,88.08,160.55,160.55,0,0,0,82.65,248.64Zm17.29,0A143.26,143.26,0,1,0,243.2,105.38,143.26,143.26,0,0,0,99.94,248.64Zm17.29,0a126,126,0,1,0,126-126A126,126,0,0,0,117.23,248.64Zm17.3,0A108.68,108.68,0,1,0,243.2,140,108.67,108.67,0,0,0,134.53,248.64Zm17.29,0a91.38,91.38,0,1,0,91.38-91.38A91.38,91.38,0,0,0,151.82,248.64Zm17.3,0a74.09,74.09,0,1,0,74.08-74.08A74.08,74.08,0,0,0,169.12,248.64Zm17.29,0a56.79,56.79,0,1,0,56.79-56.79A56.8,56.8,0,0,0,186.41,248.64Zm17.3,0a39.49,39.49,0,1,0,39.49-39.49A39.49,39.49,0,0,0,203.71,248.64Zm17.29,0a22.2,22.2,0,1,0,22.2-22.2A22.2,22.2,0,0,0,221,248.64Z"
        style={{ fill: 'none', stroke: '#999999', strokeMiterlimit: 10 }}
      />
      <circle
        id="Ellipse_28"
        data-name="Ellipse 28"
        cx="345.65"
        cy="92.42"
        r="92.42"
        style={{
          fill: '#003bff',
          stroke: '#003bff',
          strokeMiterlimit: 10,
        }}
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M297.17,278.45h206.6V70.14l.6.1C389.51,70.24,297.17,162.28,297.17,278.45Z"
        style={{
          fill: '#000',
          stroke: '#000',
          strokeMiterlimit: 10,
        }}
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M499.58,299.67C499.58,414.61,405.19,500,290.33,500H499.58Z"
        style={{
          fill: '#222',
          stroke: '#222',
          strokeMiterlimit: 10,
        }}
      />
    </g>
  </svg>
);

export default SvgHomeBanner;
