import React from 'react';

const SvgProductEngineering = ({ pathId, ...props }) => (
  <svg id="Layer_6" {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      id={pathId}
      fill="none"
      stroke="white"
      strokeWidth="2"
      data-name="noun website 137175"
      d="M236.5,228.56a1.62,1.62,0,1,1,1.15-.48A1.63,1.63,0,0,1,236.5,228.56ZM89.13,299.39a1.61,1.61,0,0,1-1.62-1.61A30.32,30.32,0,0,1,115,267.47l4-.39V228.56H20.24A19.67,19.67,0,0,1,.6,208.92V20.24A19.67,19.67,0,0,1,20.24.6H279.76A19.67,19.67,0,0,1,299.4,20.24V208.92a19.67,19.67,0,0,1-19.64,19.64H257.33a1.62,1.62,0,0,1,0-3.24h22.43a16.42,16.42,0,0,0,16.4-16.4V20.24a16.42,16.42,0,0,0-16.4-16.4H20.24a16.42,16.42,0,0,0-16.4,16.4V208.92a16.42,16.42,0,0,0,16.4,16.4h192a1.62,1.62,0,0,1,.07,3.24h-32.5v38.57l4.12.26a30.5,30.5,0,0,1,28.52,30.39,1.62,1.62,0,0,1-1.62,1.62ZM118,270.56A27.29,27.29,0,0,0,91.7,290.62l-1.51,5.54H209.81l-1.51-5.54A27.3,27.3,0,0,0,182,270.56Zm4.22-3.23h54.43V228.56H122.18Zm27.22-58a1.63,1.63,0,1,1,1.62-1.63,1.6,1.6,0,0,1-.48,1.15A1.62,1.62,0,0,1,149.4,209.34Zm-40.18-67.71a1.53,1.53,0,0,1-1.14-.47L74.4,107.48a1.62,1.62,0,0,1,0-2.29l33.68-33.68a1.62,1.62,0,0,1,2.29,2.29L77.84,106.34l32.53,32.53a1.62,1.62,0,0,1,0,2.29A1.54,1.54,0,0,1,109.22,141.63Zm81.56,0a1.55,1.55,0,0,1-1.15-.47,1.62,1.62,0,0,1,0-2.29l32.54-32.53L189.63,73.8a1.62,1.62,0,1,1,2.29-2.29l33.68,33.68a1.62,1.62,0,0,1,0,2.29l-33.68,33.68A1.53,1.53,0,0,1,190.78,141.63ZM141,143.76a1.93,1.93,0,0,1-.42,0,1.63,1.63,0,0,1-1.15-2l18-67.12A1.61,1.61,0,0,1,159,73.4a1.52,1.52,0,0,1,.42.06,1.62,1.62,0,0,1,1.15,2l-18,67.12A1.61,1.61,0,0,1,141,143.76ZM26.25,190.11a1.61,1.61,0,0,1-1.61-1.62V26.3a1.62,1.62,0,0,1,1.62-1.62h98.21a1.62,1.62,0,0,1,0,3.24H27.87v159H272.13V27.92h-93.9a1.62,1.62,0,0,1,0-3.24h95.52a1.63,1.63,0,0,1,1.62,1.62V188.49a1.62,1.62,0,0,1-1.62,1.62Zm125-162.19a1.61,1.61,0,0,1-1.14-.47,1.71,1.71,0,0,1-.48-1.15,1.62,1.62,0,0,1,3.24,0,1.69,1.69,0,0,1-.48,1.16A1.66,1.66,0,0,1,151.2,27.92Z"
    />
  </svg>
);

export default SvgProductEngineering;
