import React from 'react';

const SvgArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 42.78 74.84">
    <g id="Layer_2" data-name="Layer 2">
      <path d="M0,38.05a5.32,5.32,0,0,0,1.54,3.17L33.65,73.29a5.36,5.36,0,0,0,7.55-7.6h0L12.94,37.42,41.2,9.16a5.36,5.36,0,0,0-7.55-7.6L1.58,33.62A5.32,5.32,0,0,0,0,38.05Z" />
    </g>
  </svg>
);

export default SvgArrow;
