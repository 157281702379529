import React from 'react';
import Link from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';
import { Col } from 'react-bootstrap';

const ProjectCard = ({ bgColor, textDark, title, description, image, to }) => {
  return (
    <Col xs={12} md={6}>
      <Link paintDrip hex={bgColor || '#000000'} to={to} className="link-no-style p-3">
        <div style={{ backgroundColor: bgColor || '#003BFF' }} className={`project-card ${textDark ? 'text-dark' : 'text-white'}`}>
          <Img fluid={image} />
          {title ? <h3>{title}</h3> : null}
          {description ? <h6 className="w-75 font-weight-normal">{description}</h6> : null}
        </div>
      </Link>
    </Col>
  );
};

export default ProjectCard;
