import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ProjectCard from './project_card';

const projects = [
  { _id: 'mondoz', img: 'mondoz', to: '/project/mondoz', bgColor: '#FF8002', title: 'Mondoz', description: 'On demand food delivery platform' },
  { _id: 'pitchmatic', img: 'pitchMatic', to: '/project/pitchmatic', bgColor: '#3597EC', title: 'PitchMatic', description: 'Email automation platform' },
  { _id: 'locktrip', img: 'lockTrip', to: '/project/locktrip', bgColor: '#488BF8', title: 'LockTrip', description: 'Cryptobased Travel booking site' },
  { _id: 'cobox', img: 'cobox', to: '/project/cobox', bgColor: '#003BFF', title: 'Cobox', description: 'Shopping Coupons aggregator app' },
  { _id: 'happytosign', img: 'happyToSign', to: '/project/happytosign', bgColor: '#0085F4', title: 'Happy To Sign', description: 'Digital E-signature Platform' },
];

const ProjectsSection = () => {
  const images = useStaticQuery(graphql`
    query {
      trashLyft: file(relativePath: { eq: "trashLyft.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      cobox: file(relativePath: { eq: "cobox.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pitchMatic: file(relativePath: { eq: "pitchMatic.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lockTrip: file(relativePath: { eq: "lockTrip.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mondoz: file(relativePath: { eq: "mondoz.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      happyToSign: file(relativePath: { eq: "happyToSign.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return projects.map((proj) => <ProjectCard key={proj._id} image={images[proj.img] && images[proj.img].childImageSharp.fluid} bgColor={proj.bgColor} title={proj.title} textDark={proj.textDark} to={proj.to} description={proj.description} />);
};

export default ProjectsSection;
